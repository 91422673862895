import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login_in_status: false,
    text: null,
    color: null,
    alert: false,
    language: localStorage.getItem('lang') || 0,
    bAuthentication: false,
    offsetTop: 0,
    bSignup: false
  },
  mutations: {
    set_signup (state, value) {
      state.bSignup = value
    },
    set_offsetTop (state, value) {
      state.offsetTop = value
    },
    toggle_auth (state) {
      state.bAuthentication = !state.bAuthentication
    },
    toggle_language (state, value) {
      state.language = value
      localStorage.setItem('lang', value)
    },
    toggle_alert (state, alert) {
      state.text = alert.text
      state.color = alert.color
      state.alert = true
    },
    set_alert (state, value) {
      state.alert = value
    }
  },
})
