const routes = [
  {
    /*
    */
    path: '/user',
    name: 'Home',
    meta: { 
      requiresAuth: true 
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { 
          requiresAuth: true 
        },
        component: () => import('@/views/users/UsersHome'),
      },
      {
        path: 'products',
        name: 'Products',
        meta: { 
          requiresAuth: true 
        },
        component: () => import('@/views/users/UsersHome'),
      },
      {
        path: 'categories',
        name: 'Categories',
        meta: { 
          requiresAuth: true 
        },
        component: () => import('@/views/users/UsersHome'),
      },
      {
        path: '',
        name: '',
        meta: { 
          requiresAuth: true 
        },
        component: () => import('@/views/users/UsersHome'),
      }
    ],
    component: () => import('@/views/users/UsersHome'),
  },
]

export default routes