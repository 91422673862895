<template>
  <v-app v-scroll="onScroll" style="overflow-x: hidden;">
    <!-- view transition effect -->
    <v-fade-transition mode="out-in">
      <div>
        <main v-bind="attrs">
          <router-view />
        </main>
        <!--<v-btn style="z-index:999999" href="https://wa.me/+5218180202495" color="green" dark big fixed bottom right fab>
          <i class="fa fa-whatsapp my-float"></i>
        </v-btn>-->
      </div>
    </v-fade-transition>
    <!-- view transition effect -->

    <!-- start application's alerts -->
    <v-snackbar v-model="snackbar" :color="color">
      <span v-html="text" class="app_alert_text" />
      <v-btn :color="btn_text_color" text @click="snackbar = false" v-html="alert_close_text" />
    </v-snackbar>
    <!-- end application's alerts -->
  </v-app>
</template>

<script>
/**
 *
 * Calling alert system:
 * alerts: this.$store.commit('toggle_alert', {color: '', text: ''})
 *
 */
export default {
  data: () => ({
    snackbar: false,
    btn_text_color: "white",
    alert_close_text: "Cerrar",
  }),
  methods: {
    onScroll(e) {
      this.$store.commit(
        "set_offsetTop",
        window.pageYOffset || document.documentElement.scrollTop
      );
    },
  },
  watch: {
    // set snackbar alert to toggled
    alert() {
      this.snackbar = this.alert;
    },
    // set VueX state:alert to the current value
    snackbar() {
      this.$store.commit("set_alert", this.snackbar);
    },
    login_status: function () {
      if (this.login_status) {
        this.$router.push("/admin/applications");
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    login_status() {
      return this.$store.state.login_in_status;
    },
    token() {
      return this.$store.state.token;
    },
    alert() {
      return this.$store.state.alert;
    },
    text() {
      return this.$store.state.text;
    },
    color() {
      return this.$store.state.color;
    },
    attrs() {
      return {
        key: this.$route.path,
        style: {
          minHeight: "calc(100vh - " + this.$vuetify.application.top + "px",
        },
      };
    },
  },
};
</script>
<style>

.textarea {
  resize: none !important;
}


.para-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.33)
  );
}
@font-face {
  font-family: "Montserrat", sans-serif;
  src: url("./assets/fonts/Helvetica.ttf"); /* IE */
}
/* 
  General App CSS
*/
.app_alert_text {
  margin: auto;
  font-family: "Open sans", sans-serif;
}
.float-add{
	position:fixed;
	width:50px;
	height:50px;
	bottom:40px;
	right:50px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float-add{
	margin-top:16px;
  color: white;
}
.v-btn {
  text-transform: none;
}
body {
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
}
</style>
<style lang="sass">
section
  padding-top: 64px
  padding-bottom: 64px

  @media screen and (max-width: 640px)
    padding-top: 16px
    padding-bottom: 16px

  // Remove once fixed
  // https://github.com/vuetifyjs/vuetify/issues/8327
  .theme--dark.v-list-item
    color: #FFFFFF
  
  p
    font-family: 'Montserrat', sans-serif
  h1
    font-family: 'Montserrat', sans-serif
  h2  
    font-family: 'PT Serif', sans-serif
    line-height: 2

</style>
