const routes = [
	{
		/*
      Set Up router for guests and 
      landing page display vue file
    */
		path: '/',
		name: 'Home',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/guests/GuestHome')
	},
	{
		/*
      Set Up router for guests and 
      landing page display vue file
    */
		path: '/transportes',
		name: 'Quienes',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/guests/GuestTransportes')
	},
	{
		/*
      Set Up router for guests and 
      landing page display vue file
    */
		path: '/reciclaje',
		name: 'Manufacture',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/guests/GuestReciclaje')
	},
	{
		/*
      Set Up router for guests and 
      landing page display vue file
    */
		path: '/confinamiento',
		name: 'Comercial',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/guests/GuestConfinamiento')
	},
	{
		/*
      Set Up router for guests and 
      landing page display vue file
    */
		path: '/faq',
		name: 'Contact',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/guests/GuestFaq')
	},
	{
		/*
      Set Up router for guests and 
      landing page display vue file
    */
		path: '/contact',
		name: 'Contact',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/guests/GuestContactInfo')
	}
];

export default routes;
