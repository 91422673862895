import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';


// initiate axios as a winod variable for convinience ( ex: db.get ; db.post ; db.put ; db.delete )
window.db = axios;
window.uri = 'http://ec2-107-23-108-138.compute-1.amazonaws.com'; // 'http://ec2-52-91-114-127.compute-1.amazonaws.com'

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
